<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "เพิ่มกิจกรรม",
    meta: [
      { 
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      // tableData: tableData,

      title: "เพิ่มกิจกรรม",
      items: [
        {
          text: "ข้อมูลหลัก",
        },
        {
          text: "ข้อมูลหลักผู้แทนจำหน่าย",
        },
        {
          text: "กิจกรรม",
          href: "/d-activity"
        },
        {
          text: "เพิ่มกิจกรรม",
          active: true,
        },
      ],
      overlayFlag: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,

      // selectMode: "single",
      userBranchId: "",
      branchId: "",
      //   payMethCode: "",
      actCode: "",
      manageBranch: "",
      shortNameEn: "",
      shortNameTh: "",
      nameTh: "",

      nameEn: "",
      payMethod: "",
      submitform: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      userBranch: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,

    };
  },
  validations: {
    actCode: {
    },
    nameTh: {
      required,
    },
    nameEn: {},
    manageBranch: {
    },
    shortNameTh: {},
    shortNameEn: {},
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
  },
  created() {},
  methods: {
    /**
     * Search the table data with search input
     */

    tooltipForm() {
      // console.log(this.$v)
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitDfi();
      }
    },
    submitDfi: function() {
        this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/d-activity/store", {
          actCode: this.actCode,
          nameTh: this.nameTh,
          nameEn: this.nameEn,
          shortNameTh: this.shortNameTh,
          shortNameEn: this.shortNameEn,
          manageBranch: this.manageBranch,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "d-activity/edit", 
            params: {
              actId: response.data.actId,
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <b-overlay
                        :show="overlayFlag"
                        spinner-variant="primary"
                        spinner-type="grow"
                        spinner-small
                        rounded="sm"
                        >
    <div class="row">
      <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                 
                <div class="col-12 col-sm-12 col-md-12">
                    
                  <div class="row">
                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        รหัสกิจกรรม <br />
                        <input
                          type="text"
                          class="form-control"
                          placeholder="รหัสกิจกรรม"
                          v-model="actCode"
                          :class="{
                            'is-invalid': submitform && $v.actCode.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.actCode.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.actCode.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อ (ไทย):
                        <b-form-input
                          v-model="nameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3 position-relative">
                        ชื่อ (อังกฤษ):
                        <b-form-input
                          v-model="nameEn"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameEn.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameEn.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameEn.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ (ไทย):
                        <b-form-input
                          v-model="shortNameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.shortNameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.shortNameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.shortNameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ (อังกฤษ):
                        <b-form-input
                          v-model="shortNameEn"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.shortNameEn.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.shortNameEn.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.shortNameEn.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-1"></div>

                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        <label for="">&nbsp;</label><br />
                        <b-form-checkbox
                          v-model="manageBranch"
                          value="1"
                          unchecked-value="0"
                          class="mb-3"
                          checked
                          plain
                          :class="{
                            'is-invalid': submitform && $v.manageBranch.$error,
                          }"
                          >ส่งข้อมูลไปทุกสาขา</b-form-checkbox
                        >
                        <div
                          v-if="submitform && $v.manageBranch.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.manageBranch.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12">
                        <b-button
                          class="btn float-end m-1"
                          variant="success"
                          @click="tooltipForm"
                        >
                          บันทึก
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>
    </b-overlay>
  </Layout>
</template>
