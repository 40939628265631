var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('b-overlay',{attrs:{"show":_vm.overlayFlag,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" รหัสกิจกรรม "),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.actCode),expression:"actCode"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.actCode.$error,
                        },attrs:{"type":"text","placeholder":"รหัสกิจกรรม"},domProps:{"value":(_vm.actCode)},on:{"input":function($event){if($event.target.composing)return;_vm.actCode=$event.target.value}}}),(_vm.submitform && _vm.$v.actCode.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.actCode.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("ชื่อ (ไทย): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.nameTh.$error,
                        },model:{value:(_vm.nameTh),callback:function ($$v) {_vm.nameTh=$$v},expression:"nameTh"}}),(_vm.submitform && _vm.$v.nameTh.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.nameTh.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ชื่อ (อังกฤษ): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.nameEn.$error,
                        },model:{value:(_vm.nameEn),callback:function ($$v) {_vm.nameEn=$$v},expression:"nameEn"}}),(_vm.submitform && _vm.$v.nameEn.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.nameEn.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ชื่อย่อ (ไทย): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.shortNameTh.$error,
                        },model:{value:(_vm.shortNameTh),callback:function ($$v) {_vm.shortNameTh=$$v},expression:"shortNameTh"}}),(_vm.submitform && _vm.$v.shortNameTh.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.shortNameTh.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ชื่อย่อ (อังกฤษ): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.shortNameEn.$error,
                        },model:{value:(_vm.shortNameEn),callback:function ($$v) {_vm.shortNameEn=$$v},expression:"shortNameEn"}}),(_vm.submitform && _vm.$v.shortNameEn.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.shortNameEn.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":""}},[_vm._v(" ")]),_c('br'),_c('b-form-checkbox',{staticClass:"mb-3",class:{
                          'is-invalid': _vm.submitform && _vm.$v.manageBranch.$error,
                        },attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.manageBranch),callback:function ($$v) {_vm.manageBranch=$$v},expression:"manageBranch"}},[_vm._v("ส่งข้อมูลไปทุกสาขา")]),(_vm.submitform && _vm.$v.manageBranch.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.manageBranch.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('b-button',{staticClass:"btn float-end m-1",attrs:{"variant":"success"},on:{"click":_vm.tooltipForm}},[_vm._v(" บันทึก ")])],1)])])])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }